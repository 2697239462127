export function replaceTemplate(template: string, replacements: Record<string, string>): string {
    let message = template;
    for (const key in replacements) {
        if (message.includes('${' + key + '}')) {
            message = message.replace('${' + key + '}', replacements[key]);
        }
    }
    return message;
}


export function dateFormatter(nowDate: Date) : string {
    return nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1)
        + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':' +
        nowDate.getMinutes() + ':' + nowDate.getSeconds()
}

