import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './css/mediateInfo.css';
import {Empty, Image, Toast} from "antd-mobile";
import {buryingPoint, queryH5ByCaseId} from "../apis/httpInterface";
import {replaceTemplate} from "../utils/Tools";
import {UAParser} from 'ua-parser-js';

type CollectionListType = {
  title: string,
  time: string,
  detail: string,
  key: boolean,
}
type EntrustType = {
  title: string,
  detail: string,
  key: string | null,
  needLine: boolean
};
const kEntrustList = [
  {
    title: '申请人',
    detail: '${signCompany}',
    key: 'signCompany',
    needLine: true
  },
  {
    title: '被申请人',
    detail: '${debtor}',
    key: 'debtor',
    needLine: true
  },
  {
    title: '申请人诉情',
    detail: '申请返还逾期本金利息,共计${overDueAmount}',
    key: 'overDueAmount',
    needLine: true
  },
  {
    title: '案件描述',
    detail: '信用卡纠纷/借款合同纠纷',
    key: null,
    needLine: true
  },
  {
    title: '委派机构',
    detail: '重庆市江北区西部商事调解中心',
    key: null,
    needLine: false
  }
];
const kCollectionList = [
  {
    title: '调解申请',
    time: '--',
    key: true,
    detail: '${signCompany}向重庆市江北区人民法院提交调解申请'
  },
  {
    title: '调解审核',
    time: '--',
    key: false,
    detail: '重庆市江北区人民法院对调解请求符合受理条件与否进行审查'
  },
  {
    title: '调解委派',
    time: '--',
    key: false,
    detail: '重庆市江北区人民法院将案件委派给重庆市江北区西部商事调解中心'
  },
  {
    title: '调解分配',
    time: '--',
    key: true,
    detail: '调解中心将案件分配给${realName}调解员'
  }
];


const MediateInfo = () => {
  const  navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const initialCaseId = searchParams.get('page') ? searchParams.get('page') : 0;
  const [caseId] = useState(initialCaseId);
  const [entrustInfoList, setEntrustInfoList] = useState<Array<EntrustType>>([]);
  const [mediate, setMediate] = useState('---');
  const [collection, setCollection] = useState<Array<CollectionListType>>([]);
  const [caseNo, setCaseNo] = useState('---');
  const [year, setYear] = useState('---');
  const [monthDay, setMonthDay] = useState('---');
  const [mediatorId, setMediatorId] = useState<number>(0);

  useEffect(() => {
    const parser = new UAParser();
    if (caseId) {
      queryH5ByCaseId(caseId).then((r: any) => {
        if (r.executed) {
          setMediate(r.data.realName);
          kCollectionList.forEach(e => {
            e.time = r.data.entrustingTime;
            if (e.key) {
              e.detail = replaceTemplate(e.detail, r.data);
            }
          });
          setCollection(kCollectionList);
          setCollection(kCollectionList);
          kEntrustList.forEach(e => {
            if (e.key) {
              e.detail = replaceTemplate(e.detail, r.data);
            }
          })
          setEntrustInfoList(kEntrustList);
          setCaseNo(r.data.caseNo);
          setYear(r.data.year);
          setMonthDay(r.data.monthDay);
          setMediatorId(r.data.mediatorId)
        }
      });
      try {
        const data = {
          event: 'homePage',
          caseId,
          platform: parser.getBrowser() ? parser.getBrowser().name : '',
          manufacturer: parser.getDevice() ? parser.getDevice().vendor : '',
          model: parser.getDevice() ? parser.getDevice().model : '',
          systemVersion: parser.getOS() ? parser.getOS().version : '',
          systemName: parser.getOS() ? parser.getOS().name : '',
        };
        buryingPoint(data).then(r => {});
      }catch (e) {
        console.log(e)
      }
    } else {
      kEntrustList.forEach(e => {
        e.detail = '---'
      })
      setEntrustInfoList(kEntrustList);
    }
  },[]);
  const toChatPage = () => {
    if (!mediatorId) {
      Toast.show({
        icon: "fail",
        content: '暂时信息!'
      })
      return;
    }
    navigate('/chat', {state: {caseId, mediatorId}});
  }
  /**
   * Render
   */
  const renderTitle = (title: string) => {
    return (
      <div className={'section-title'}>
        {title}
      </div>
    )
  };
  const renderEntrustInfo = () => {
    return (
      <div className={'section-box'} style={{marginTop: 220}}>
        {renderTitle(`(${year}）渝${monthDay}互预调（${caseNo}）号`)}
        {entrustInfoList.map((e, index) => {
          return (
            <div key={index} className={'entrust-item'} style={e.needLine ? {borderBottom: 'solid 1px #F5F6FA'} : {}}>
              <span style={{fontSize: 15, color: '#151516', fontWeight: 400}}>{e.title}</span>
              <span style={{fontSize: 15, color: '#5E5F62', fontWeight: 400, textAlign: "right"}}>{e.detail}</span>
            </div>
          )
        })}
      </div>
    );
  };
  const renderMediateInfo = () => {
    return (
      <div className={'section-box'}
           style={{
             marginTop: 12,
             padding: '12px 16px'}}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
          <Image width={42} height={42} src={require('../assets/ic_online_mediate.png')}/>
          <div style={{
            marginLeft: 24,
            display: "flex",
            flexDirection: "column"
          }}>
            <span style={{fontSize: 13, color: '#5E5F62', fontWeight: 400}}>调解员 <span style={{color: '#46464B'}}>{mediate}</span></span>
            <span style={{marginTop: 6, fontSize: 13, color: '#5E5F62', fontWeight: 400}}>023-65795700</span>
          </div>
        </div>
      </div>
    )
  };
  const renderCollectionInfo = () => {
    return (
      <div className={'section-box'}
           style={{marginTop: 12}}>
        {renderTitle('案件进度')}
        {collection.length ? <div style={{padding: '16px 16px 0 16px'}}>
          {collection.map((e, index) => {
            return (
              <div key={index} style={{display: "flex", flexDirection: "row", alignSelf: "start", paddingBottom: 10}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <div className={'collection-index'}>{index + 1}</div>
                  <div className={'collection-left-line'}/>
                </div>
                <div style={{marginLeft: 6, minWidth: 90, display:"flex", flexDirection: "column"}}>
                  <span style={{color: '#151516', fontSize: 15, fontWeight: 400}}>{e.title}</span>
                  <span style={{marginTop: 4, color: '#8E8F97', fontSize: 13, fontWeight: 400}}>{e.time}</span>
                </div>
                <div style={{color: '#5E5F62', fontSize: 13, fontWeight: 400}}>{e.detail}</div>
              </div>
            )
          })}
        </div> : <Empty description='暂无数据'/>}
      </div>
    )
  };
  const renderBtnInfo = () => {
    return (
      <div onClick={toChatPage} className={'btn'} style={{background: 'linear-gradient( 119deg, #CB0A0E 0%, #A81213 100%)'}}><span>在线调解</span></div>
    )
  };
  return (
    <div className={'root'}>
      <Image className={'img-bg'} lazy={true} src={require('../assets/ic_online_bg.png')}/>
      {renderEntrustInfo()}
      {renderMediateInfo()}
      {renderCollectionInfo()}
      {renderBtnInfo()}
    </div>
  )
}
export default MediateInfo
