import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import MediateInfo from "./pages/mediateInfo";
import Chat from "./pages/chat";

function App() {
  useEffect(() => {
    console.log('==========启动成功==========')
  },[])
  return (
    <div className="App">
      <Routes>
        <Route path={'/'} element={<MediateInfo/>}/>
        <Route path={'/chat'} element={<Chat/>} />
      </Routes>
    </div>
  );
}

export default App;
