import request from "../utils/request";

export function queryH5ByCaseId(caseId) {
  return request({
    url: '/case/queryH5ByCaseId',
    method: 'post',
    params: { caseId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function queryChatRecordPage(data) {
  return request({
    url: '/chatrecord/queryChatRecordPage',
    method: 'post',
    data,
  })
}

export function chatInitialization(caseId) {
  return request({
    url: '/chatWindow/chatInitialization',
    method: 'get',
    params: { caseId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export async function buryingPoint(data) {
  let response = null;
  try {
    response = await request('https://api.ipify.org?format=json');
  }catch (e) {}
  Object.assign(data, {
    source: 'mediate-h5',
    ip: response ? response.ip : ''
  });
  return request({
    url: '/collection/buryingPoint',
    method: 'post',
    data,
  })
}
