import React, { useEffect, useRef, useState } from "react";
import './css/chat.css';
import { Button, Empty, Image, Input, InputRef, NavBar, Toast } from "antd-mobile";
import { MessageType } from "../apis/model";
import { useLocation } from "react-router-dom";
import {buryingPoint, chatInitialization, queryChatRecordPage} from "../apis/httpInterface";
import {GetBaseWsUrl} from "../utils/request";
import {UAParser} from 'ua-parser-js';
import {dateFormatter} from "../utils/Tools";
const WSURL = GetBaseWsUrl() + '/websocket/';
const webCloseCode: number = 1000;
export const MessageTypeOpen: string = 'MessageTypeOpen';
export const MessageTypeReadBack: string = 'MessageTypeReadBack';
export const MessageTypeRelay: string = 'MessageTypeRelay';

const Chat: React.FC = () => {
  const mediatorId = useLocation().state.mediatorId ? useLocation().state.mediatorId : 0;
  const caseId = useLocation().state.caseId ? useLocation().state.caseId : 0;
  const inputRef = useRef<InputRef>(null);
  const [msgList, setMsgList] = useState<Array<MessageType>>([]);
  const [inputValue, setInputValue] = useState('');
  let wsRef = useRef<WebSocket | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  let scrollTimer: NodeJS.Timeout;
  const [lastScrollerIndex, setLastScrollerIndex] = useState(-1);
  //心跳
  let heartbeatInterval: number;

  useEffect(() => {
    const parser = new UAParser();
    try {
      GetChatInitialization();
    }catch (e) {}
    try {
      const data = {
        event: 'chatPage',
        caseId,
        platform: parser.getBrowser() ? parser.getBrowser().name : '',
        manufacturer: parser.getDevice() ? parser.getDevice().vendor : '',
        model: parser.getDevice() ? parser.getDevice().model : '',
        systemVersion: parser.getOS() ? parser.getOS().version : '',
        systemName: parser.getOS() ? parser.getOS().name : '',
      };
      buryingPoint(data).then(r => {});
    }catch (e) {}
    // 获取消息记录
    queryChatRecordPage({
      caseId,
      mediatorId,
      page: 1,
      size: 999999,
    }).then(res => {
      let records = res.data.records
      if (records.length > 0) {
        records.forEach((el: any) => {
          addMessageToList({
            msgId: el.id,
            role: el.role,
            content: el.content,
            time: el.time,
            isRead: el.isread
          })
        });
      } else {
        let nowDate = new Date();
        addMessageToList({
          msgId: null,
          role: 'mediator',
          content: '您好，请问有什么可以帮你？',
          time: dateFormatter(nowDate),
          isRead: true
        })
      }
    });
    const ws = new WebSocket(WSURL + `${caseId}`);
    wsRef.current = ws;
    ws.onopen = function (evt) {
        startHeartbeat()
    };
    ws.onmessage = function (evt) {
      try {
        const resData = JSON.parse(evt.data)
        if (resData) {
          if (resData.messageType == MessageTypeRelay) {
            const msg : MessageType = {
              msgId: resData.msgId,
              role: resData.type,
              content: resData.msg,
              time: resData.time,
              isRead: false
            }
            addMessageToList(msg);
          } else if (resData.messageType == MessageTypeReadBack) {
            setMsgList(prevState => {
              return prevState.map(e => {
                if (e.msgId == resData.msgId) {
                  return {...e, isRead: true}
                }
                return e;
              })
            })
          }
        }
      } catch (er) {
        console.log(er)
      }
    };
    ws.onclose = function (evt) {
      stopHeartbeat();
      if (evt.reason !== '主动断开') {
        reConnectWebSocket();
      }
    };

    return () => {
      if (ws) {
        ws.close(webCloseCode, '主动断开');
        stopHeartbeat();
      }
    }
  }, []);
  const startHeartbeat = () => {
    stopHeartbeat();
    heartbeatInterval = window.setInterval(() => {
      sendHeartbeat()
    }, 1000 * 10);
  }
  const stopHeartbeat = () => {
    if (heartbeatInterval) {
      window.clearInterval(heartbeatInterval);
    }
  }
  const sendHeartbeat = () => {
    const data = {
      method: 'keepAlive',
      userId: caseId
    }
    if (wsRef.current && wsRef.current?.readyState == wsRef.current?.OPEN) {
      wsRef.current.send(JSON.stringify(data));
    }
  }
  useEffect(() => {
    if (messagesEndRef.current) {
      const scrollHeightTem: number = messagesEndRef.current.scrollHeight;
      if (scrollHeightTem >= 200) {
        messagesEndRef.current.scrollTop = scrollHeightTem
      }
    }
  },[msgList]);

  useEffect(() => {
    if (lastScrollerIndex >= 0 && lastScrollerIndex < msgList.length) {
      let indexTem = lastScrollerIndex;
      for (indexTem; indexTem < msgList.length; indexTem++) {
        const msg = msgList[indexTem];
        if (msg) {
          if (!msg.isRead && msg.role == 'mediator') {
            sendMsgAreadyRead(msg);
          }
        }
      }
    }
  }, [lastScrollerIndex]);
  /**
   * 查询接口
   * @param msg
   */
  const GetChatInitialization = () => {
    if (caseId > 0) {
      chatInitialization(caseId).then(r => {
        if (r.data) {
          let nowDate = new Date();
          addMessageToList({
            msgId: null,
            role: 'mediator',
            content: r.data,
            time: dateFormatter(nowDate),
            isRead: true
          });
        }
      });
    }
  }

  const sendMsgAreadyRead = (msg: MessageType) =>{
    if (msg.msgId) {
      const postMessage = {
        'method': 'read',
        'msgId': msg.msgId
      }
      if (wsRef.current) {
        wsRef.current.send(JSON.stringify(postMessage));
      }
    }
  }

  const handleScroll = (e: any) => {
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    scrollTimer = setTimeout(() => {
      const element = e.target;
      const scrollTopTem = element.scrollTop + 44;
      const msgListChildren = element.children[1];
      if (msgListChildren && msgListChildren.children) {
        for (let i = 0; i < msgListChildren.children.length; i++) {
          const child = msgListChildren.children[i];
          const childOffsetTop = child.offsetTop;
          if (scrollTopTem < childOffsetTop) {
            setLastScrollerIndex(i)
            break;
          }
        }
      }
    }, 250); // 设置延迟时间，单位为毫秒
  }

  const reConnectWebSocket = () => {
    wsRef.current = new WebSocket(WSURL + `${caseId}`);
  };
  const addMessageToList = (data: MessageType) => {
    setMsgList((prevMsgList) => {
      if (data) {
        const msgListTem: Array<MessageType> = [...prevMsgList]; // 创建一个新数组并复制原始数组的内容
        msgListTem.push(data);
        return msgListTem;
      } else {
        return prevMsgList;
      }
    });
  };

  /**
   * send Message
   */
  function sendWithCallback(data: string, successCallback: (msg: MessageType) => void, errorCallback: (arg0: unknown) => void) {
    if (wsRef.current) {
      try {
        const postMessage = {
          toUserId: mediatorId,
          type: 'customer',
          msg: data
        }
        wsRef.current.send(JSON.stringify(postMessage));
        if (typeof successCallback === 'function') {
          successCallback({
            msgId: null,
            role: 'customer',
            content: data,
            time: new Date().toDateString(),
            isRead: false
          });
        }
      } catch (error) {
        if (typeof errorCallback === 'function') {
          errorCallback(error);
        }
      }
    } else {
      if (typeof errorCallback === 'function') {
        errorCallback(new Error('网络错误'));
      }
    }
  }
  const btnSendAction = () => {
    if (inputValue.length <= 0) {
      Toast.show({
        content: '请输入内容!'
      })
      return;
    }
    sendWithCallback(inputValue, (msg) => {
      if (inputRef.current) {
        inputRef.current.clear();
      }
      // addMessageToList(msg);
    }, (er) => {
      Toast.show({
        icon: "fail",
        content: '发送失败！!'
      })
    });
  };
  const handleInputOnChange = (value: string) => {
    setInputValue(value);
  }
  const back = () => {
    window.history.back();
  };
  const renderMessageReceiveUI = (message: MessageType, index: number) => {
    return (
      <div className={'msg-receive-box'} key={index}>
        <Image width={40} height={40} src={require('../assets/ic_online_mediate.png')} />
        <div className={'content'}>{message.content}</div>
      </div>
    )
  };
  const renderMessageSendUI = (message: MessageType, index: number) => {
    return (
      <div key={index} className={'msg-send-box'}>
        <div className={'content'}>{message.content}</div>
        {message.isRead ? <div className={'read-status'}>已读</div> : null}
      </div>
    )
  }
  const renderMessageUI = (message: MessageType, index: number) => {
    if (message.role == 'mediator') {
      return renderMessageReceiveUI(message, index);
    } else {
      return renderMessageSendUI(message, index);
    }
  };
  const renderInputMessageUI = () => {
    return (
      <div className={'input-box'}>
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputOnChange}
          style={{ marginLeft: 20, marginRight: 15 }}
          clearable />
        <Button onClick={btnSendAction} className={'btn'} shape='rounded' color='primary'>发送</Button>
      </div>
    )
  }
  return (
    <div className={'chat-root'} onScroll={handleScroll} ref={messagesEndRef}>
      <NavBar style={{ backgroundColor: '#FFF', color: '#151516', fontWeight: 500 }} onBack={back}>在线调解</NavBar>
      {msgList.length ? <div className={'message-box'}>
        {msgList.map((e, index) => {
          return renderMessageUI(e, index)
        })}</div> : <div style={{ paddingTop: '25%', minHeight: 'calc(100% - 180px)' }}><Empty description='暂无在线调解信息' /></div>}
      {renderInputMessageUI()}
    </div>
  )
}
export default Chat;
